/* DropdownMenu.css */
.mobile-menu {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: rgba(255, 255, 255, 1); /* Match the background color of the navbar */
  color: rgb(0, 0, 0); /* Match the text color of the navbar */
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1.3rem; /* Match the font size of the navbar */
}

.dropdown-menu {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 1); /* Match the background color of the navbar */
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu li {
  padding: 10px;
  text-align: center;
}

.dropdown-menu a {
  text-decoration: none;
  color: rgb(0, 0, 0); /* Match the text color of the navbar */
  display: block;
  font-size: 1.3rem; /* Match the font size of the navbar */
}

.mobile-menu:hover .dropdown-menu {
  display: block;
}

/* Style for mobile devices */
@media (max-width: 768px) {
  .mobile-menu {
    display: block;
  }

  .dropdown-button {
    display: block;
    width: 100%;
    padding-left: 1em;
  }

  .dropdown-menu {
    position: static;
  }

  .mobile-menu:hover .dropdown-menu {
    display: block;
  }
}

