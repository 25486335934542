.edit-product-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.search-container {
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%; 
}

.search-input {
  width: calc(100% - 20px); 
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; 
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.product-item {
  padding: 10px;
  cursor: pointer;
}

.product-item:hover,
.product-item.selected {
  background-color: #f2f2f2;
}

.form-container {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea-field {
  resize: vertical;
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #0b09a7;
  color: #fff;
  cursor: pointer;
}

.save-button:hover {
  background-color: #3b39b2;
}

.save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
