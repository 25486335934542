.order-details-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.order-details-info p {
  flex: 1;
  margin: 0;
  text-align: center;
}
