.edit-locations-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.edit-locations-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto;
  margin-top: 50px;
  margin-right: 20px;
}

.add-location-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto;
  margin-top: 50px;
  margin-left: 20px;
}

.edit-locations-title {
  text-align: center;
}

.locations-dropdown {
  margin-bottom: 15px;
}

.form-container {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.edit-location-input {
  width: 95%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.save-button {
  background-color: #0b09a7;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.save-button:hover {
  background-color: #3b39b2;
}
