.cart-page {
  margin: 20px;
  padding: 20px;
}

.cart-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 30px;
}

.cart-na{
  text-align: center;
  font-size: 22px;
  margin-bottom: 12px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed; /* Ensure equal width for all columns */
}

.cart-table th,
.cart-table td {
  padding: 10px;
  text-align: center;
  border: none; /* Remove the border */
}

.cart-table th {
  background-color: #333;
  color: #fff;
}

.cart-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.checkout-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #0b09a7;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.checkout-button:hover {
  background-color: #3b39b2;
}

.empty-cart-message {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.quantity-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-btn {
  display: inline-block;
  padding: 5px 10px;
  background-color: #ddd;
  color: #333;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.quantity-btn:hover{
  cursor: pointer;
  background-color: rgb(184, 184, 184)
}

.quantity-input {
  width: 50px;
  text-align: center;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  padding: 5px;
  padding-left: 13px;
  font-size: 14px;
}

/* New styles for cart summary */
.cart-summary {
  margin-top: 20px;
  text-align: right;
}

.cart-summary > div {
  margin-bottom: 10px;
}

.cart-summary span:first-child {
  font-weight: bold;
  margin-right: 10px;
}

.cart-summary span:last-child {
  color: #333;
}

.checkout-container {
  display: flex;
  justify-content: flex-end;
}

.tax {
  margin-top: 5px;
}

.grand-total {
  margin-top: 25px;
  font-size: 18px;
}

.delete-cart-button:hover{
  cursor: pointer;
  background-color: rgb(184, 184, 184)
}

.cart-img {
  width: 80px;
}

/* Mobile styles for screens up to 768px */
@media (max-width: 768px) {
  .cart-page {
    margin: 10px;
  }

  .cart-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .cart-table th,
  .cart-table td {
    font-size: 9px;
  }

  .quantity-btn {
    padding: 2px 4px;
    font-size: 8px;
  }

  .quantity-input {
    width: 10px;
    font-size: 8px;
    text-align: center;
    padding-left: 1px;
    margin-right: 0;
  }

  .checkout-button {
    font-size: 10px;
    padding: 6px 12px;
    margin-top: 10px;
  }

  .cart-summary {
    margin-top: 10px;
  }

  .cart-summary span:first-child {
    margin-right: 3px;
  }

  .checkout-container {
    flex-direction: column-reverse;
    margin-top: 10px;
  }

  .grand-total {
    margin-top: 15px;
    font-size: 12px;
  }

  .delete-cart-button:hover {
    background-color: #ddd;
  }

  /* Adjust product image size */
  .cart-img {
    max-width: 45px
  }

  .subtotal {
    font-size: 75%;
  }

  .tax {
    font-size: 60%;
  }

  .grand-total{
    font-size: 81%;
  }
}
