.locations-page {
  display: grid;
  grid-template-columns: 1fr;
}

.locations-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f1f1f1;
  padding: 20px;
}

.locations-list h1 {
  font-size: 50px;
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 3px solid black;
  margin-top: 30px;
  line-height: 1.2;
}

.location-item {
  margin-bottom: 26px;
}

.location-name {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  color: inherit; /* Add this line to preserve the original color */
  text-decoration: underline; /* Add this line to remove underline */
}

.location-name:hover{
  color: lightslategray;
  cursor: pointer;
}

.location-name.active {
  color: inherit; /* Keep the same color when active */
}

.location-address{
  font-size: 18px;
  padding-left: 6px;
}

.leaflet-container {
  height: 75vh;
  background-color: #f1f1f1;
  z-index: 0;
}

@media (max-width: 768px) {
  .leaflet-container {
    display: none;
  }

  .location-item,
  .location-name,
  .location-address {
    text-align: center;
  }

  .location-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .location-name {
    width: 100%;
    pointer-events: none;
  }

  .location-address {
    font-size: 13px;
    margin-top: 6px;
    padding-left: 0px;
    color: black;
  }

  .locations-list h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (min-width: 769px) {
  .locations-page {
    grid-template-columns: 1fr 1fr; /* Restore the two-column layout for larger screens */
    grid-gap: 20px;
    padding: 20px;
  }
}