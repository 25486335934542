.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 900px; /* Increase the maximum width */
  width: 100%;
  position: relative; /* Add position relative */
}

.close-button {
  position: absolute; /* Position the button absolutely */
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
  width: 30px; /* Set width for the X */
  height: 30px; /* Set height for the X */
  background-color: transparent; /* Transparent background */
  border: none; /* No border */
  cursor: pointer;
}

.close-button::before,
.close-button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #333;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.order-received-message-title{
  margin-top: 10px;
  font-size: 28px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-align: center;
}

.order-received-message {
  margin-top: 20px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  text-align: center;
}