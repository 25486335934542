.create-product-card {
  max-width: 500px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto;
  margin-top: 50px;
}

.create-product-title {
  text-align: center;
}

.create-product-form {
  display: grid;
  gap: 10px;
}

.create-product-form label {
  font-weight: bold;
}

.create-product-form input,
.create-product-form textarea {
  width: 95%;
}

.create-product-button {
  margin-top: 10px;
  background-color: #0b09a7;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 52%; /* Set the width to 52% */
  display: block; /* Add display block to center the button */
  margin-left: auto; /* Add margin-left auto to center the button */
  margin-right: auto; /* Add margin-right auto to center the button */
}

.create-product-button:hover {
  background-color: #3b39b2;
}

.create-product-form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.create-product-form select option {
  font-size: 16px;
}