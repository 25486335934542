.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
  z-index: 10;
  position: relative;
}

.navbar-logo-img {
  height: 62px;
}

.nav-link {
  color: rgb(0, 0, 0);
}

.login-link {
  color: rgb(0, 0, 0);
}

.navbar-right {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  transition: 0.3s ease-in-out;
}

.cart-icon {
  color: rgb(0, 0, 0);
  padding-left: 1.5rem;
  margin-top: 0.5em;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
}

.navbar-nav li {
  list-style: none;
  padding: 0 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.cart-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-nav :hover {
  color: lightslategray;
  text-decoration: underline;
}

.navbar-right :hover {
  color: lightslategray;
  text-decoration: underline;
}

.login-link:hover {
  text-decoration: underline;
}

.cart-icon:hover {
  color: lightslategray;
  text-decoration: underline;
}

.cart-count {
  position: absolute;
  top: -2px;
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
}

.logout-button {
  color: rgb(0, 0, 0);
  border: 0;
  font-size: 1em;
  font-weight: 520;
  background-color: #ffffff;
  font: bold;
}

.logout-button:hover{
  cursor: pointer;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  color: rgb(0, 0, 0);
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
}

.dropdown-item {
  display: block;
  padding: 5px;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: lightslategray;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Mobile CSS */
@media (max-width: 768px) {
  body {
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .navbar {
    width: auto; /* Force the navbar to span the entire width */
    padding: 15px 20px; /* Adjust padding for smaller screens */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
    justify-content: space-between; /* Ensure items are spaced evenly */
    align-items: center; /* Center items vertically */
  }

  .navbar-logo-img {
    max-width: 100%; /* Make sure the logo fits within the narrower navbar */
      height: 2.5em;
  }

  .cart-link{
    height: auto;
    width: auto;
  }

  .navbar-nav {
    flex-direction: row; /* Stack navigation links horizontally */
    align-items: center; /* Center links vertically */
    justify-content: space-between; /* Space links evenly */
    width: 60%; /* Adjust width to prevent overcrowding */
  }

  .navbar-nav li {
    padding: 0; /* Remove the padding on mobile */
    margin: 0; /* Remove margin */
    text-align: center; /* Center text */
    flex: 1; /* Distribute available space evenly */
  }

  .navbar-brand {
    display: flex;
  }

  .navbar-right {
    margin-top: 0px; /* Adjust spacing between right-aligned items */
  }

  .cart-icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  .cart-count {
    top: -1px; /* Adjust position */
    right: -4px; /* Adjust position */
  }

  .logout-button {
    height: 1.1rem; 
    width: 1.1rem;
    font-size: 1.1rem; 
    padding-right: 2.6em; 
    padding-bottom: 1.2em;
  }

  .login-link {
    font-size: 1.1rem;
  }
}
