.shop-section {
  display: flex;
}

.sidebar-category {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* border-radius: 15px; */
  font-size: 18px;
  width: 200px;
  padding: 20px;
  cursor: pointer;
  margin-right: 20px;
  text-align: right;
  border-right: 2px solid #ddd;
}

.product {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 50px;
  margin-right: 24px;
}

.image:hover {
  transform: translateY(-20px) scale(1.1, 1.1);
  transition: transform 0.2s;
  cursor: pointer;
}

.product-box {
  margin: 1.5em 1em;
  padding: 10px;
  margin-bottom: auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  overflow: hidden; /* Added overflow property */
}

.image {
  width: 300px;
  height: 300px;
  border-radius: 300%;
  margin: auto;
}

.product_name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 180px;
  padding: 16px 10px 10px 10px;
  font-size: 18px;
  border-radius: 15px;
  cursor: pointer;
  word-wrap: break-word;
}

.category-name {
  text-align: left;
  white-space: normal;
}

.category-box--selected {
  background-color: #ddd;
  margin-bottom: 8px;
  margin-right: 12px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.379);
  transition: background-color 0.5s, transform 0.5s;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .shop-section {
    flex-direction: column;
  }

  .sidebar-category {
    width: auto;
    padding: 10px;
    padding-top: 1.5em;
    margin-right: 0.8em;
    margin-left: 0.8em;
    border-right: none;
    text-align: center;
  }

  .sidebar-category select {
    width: 97%; /* Take up 100% of the width */
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #ddd;
    text-align: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
  }

  .product {
    flex-direction: row;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .image:hover {
    transform: none;
  }

  .product-box {
    width: 34.7%;
    height: 170px;
  }

  .product-price-shop{
    margin-top: .25em;
    font-size: .75em;
  }

  .product_name {
    font-size: 0.75em;
  }

  .image {
    width: 100%;
    height: 129.5px;
    border-radius: 8px;
    margin: 0;
  }

  .category-box {
    width: 100%;
    padding: 10px;
  }

  .category-box--selected {
    padding: 10px;
    transform: none;
  }
}