.homepage-container {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.slideshow-container {
  width: 52%;
  margin-right: 20px;
  margin-top: 60px;
  height: 775px
}

.text-container {
  width: 40%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.shop-now-link{
  width: 80px
}

h1 {
  font-size: 45px;
  margin-bottom: 12px;
}

p {
  font-size: 20px;
  margin-bottom: 30px;
}

.shop-now-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0b09a7;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
}

.shop-now-link:hover {
  background-color: #3b39b2;
}

@media (max-width: 768px) {
  .homepage-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    padding: 10px; /* Adjust padding for smaller screens */
    height: 35rem;
  }

  .slideshow-container {
    width: 100%; /* Full width for the slideshow container */
    margin-right: 0; /* Remove margin on the right */
    margin-top: 20px; /* Adjust top margin */
    height: 200px; /* Let the height adapt to content */
    padding-top: 1em;
  }

  .text-container {
    width: 100%; /* Full width for the text container */
    height: auto; /* Let the height adapt to content */
    margin-right: 0; /* Remove margin on the right */
    text-align: center; /* Center text */
  }

  .shop-now-link {
    width: auto; /* Let the width adapt to content */
    margin-top: 20px; /* Add top margin */
  }

  h1 {
    font-size: 30px; /* Reduce font size for mobile */
  }

  p {
    font-size: 18px; /* Reduce font size for mobile */
    margin-bottom: 20px; /* Adjust margin */
  }
}