.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.contact-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.p1 {
  font-size: 18px;
  margin-bottom: 20px;
}

.p2 {
  font-size: 18px;
  margin-bottom: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
}

label {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

textarea {
  resize: vertical;
}

.submit-button {
  background-color: #0b09a7;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3b39b2;
}

/* Mobile */
@media (max-width: 768px) {
  .contact-container {
    width: 99%;
    max-width: 300px;
    height: auto;
    margin: 30px auto;
    padding: 20px;
  }

  .contact-container h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .p1,
  .p2 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .contact-form {
    align-items: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  label {
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
  }

  input,
  textarea {
    width: 92%;
  }

  .submit-button {
    width: 90%;
  }
}
