.container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Updated */
  min-height: 100vh;
  padding: 50px;
}

.single-product {
  display: flex;
  align-items: center;
  width: 70%;
  max-width: 1000px;
  padding: 40px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.product-image-container {
  flex-basis: 40%;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.product-details-container {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 40px;
  /* margin-top: -50px; Updated */
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.product-description {
  font-size: 16px;
  text-align: justify;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.product-price {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-toggle { 
  position: relative;
  display: flex;
  align-items: center;
  background-color: #0b09a7;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-toggle-admin { 
  position: relative;
  display: flex;
  align-items: center;
  color: black;
  padding: 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-toggle:hover {
  background-color: #3b39b2;
}

.play-icon {
  margin-right: 5px;
  font-size: 16px;
}

.dropdown-text {
  margin-left: 10px;
}

.video-container {
  margin-top: 20px;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.quantity-container label {
  margin-right: 10px;
}

.quantity-container input {
  width: 50px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-to-cart-button {
  padding: 12px 24px;
  background-color: #0b09a7;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.add-to-cart-button:hover {
  background-color:  #3b39b2;
}

.fa {
  font-size: 16px;
}

.pickup-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pickup-icon {
  margin-right: 10px;
  font-size: 24px;
}

.pickup-message {
  font-size: 16px;
  font-style: bold;
}

/* Your existing CSS rules here */

@media (max-width: 768px) {
  .container {
    align-items: center; /* Center content vertically on smaller screens */
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .single-product {
    width: 100%; /* Make the product container full width on mobile */
    flex-direction: column; /* Stack elements vertically on mobile */
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .product-image-container {
    flex-basis: 100%; /* Make the product image full width on mobile */
    margin-bottom: 20px; /* Add space between image and details */
  }

  .product-details-container {
    flex-basis: 100%; /* Make the product details full width on mobile */
    padding-left: 0; /* Remove left padding for smaller screens */
  }

  .product-title {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  .product-description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .product-price {
    font-size: 18px; /* Reduce font size for smaller screens */
    margin-bottom: 20px;
  }

  .quantity-container {
    margin-bottom: 20px;
  }

  .quantity-container label {
    margin-right: 10px;
  }

  .quantity-container input {
    width: 100px; /* Adjust input width for smaller screens */
  }

  .add-to-cart-button {
    padding: 12px 24px;
    font-size: 18px; /* Reduce font size for smaller screens */
    border-radius: 8px;
  }

  .pickup-container {
    margin-bottom: 20px;
  }

  .pickup-icon {
    margin-right: 10px;
  }

  .pickup-message {
    font-size: 16px;
  }

  .video-container iframe {
    width: 100%; /* Adjust the width as needed for mobile */
    height: auto; /* Let the height adjust proportionally based on the width */
  }

  /* Additional mobile styles or overrides can be added here */
}
