.footer {
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Adjusted property */
  flex-shrink: 0;
}

.footer-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.footer-section:not(:last-child) {
  border-right: 2px solid #ddd;
}

.newsletter {
  text-align: center;
  margin-bottom: 20px;
}

.newsletter h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.newsletter form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter input[type="email"] {
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
  font-size: 18px;
}

.newsletter button {
  padding: 8px 16px;
  background-color: #0b09a7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.newsletter button:hover{
  background-color: #3b39b2
}

.links-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}

.links-section a {
  margin: 5px 10px;
}

.links a {
  margin: 5px 10px;
  color: #000; /* Set the regular font color */
  text-decoration: none; /* Remove underline */
}

.links a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.privacy-terms {
  display: flex;
  align-items: center;
}

.privacy-terms a {
  margin-right: 5px;
  color: inherit; /* Keep the same color when active */
}

.social-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 7px
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  margin: 5px 10px;
  font-size: 24px;
  color: #777;
}

.social-text {
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.spacer{
  padding-left: 10px;
}

.rights-text {
  text-align: center;
  font-size: 12px;
}

@media (max-width: 768px) {
  .footer {
    padding: 10px; /* Adjust padding for smaller screens */
    flex-direction: column; /* Stack sections on top of each other */
    text-align: center; /* Center text within sections */
  }

  .footer-section {
    padding: 10px;
    border-right: none; /* Remove border from all sections */
    margin-bottom: 10px; /* Add spacing between sections */
  }

  .newsletter h3 {
    font-size: 0.9em;
  }

  .newsletter button {
    font-size: .75em;
  }

  .newsletter input[type="email"] {
    width: 100%; /* Make the input full width */
    margin: 10px 0; /* Add spacing between input and button */
    font-size: .75em;
  }

  .links-section a {
    margin: 5px 0; /* Adjust spacing between links */
  }

  .social-links a {
    margin: 5px 10px; /* Adjust spacing between social icons */
  }

  .footer-section:not(:last-child) {
    border-right: 0px solid #ddd;
  }

  .spacer {
    padding-left: 1em;
    padding-right: 1em;
  }

  .subscribe-button {
    size: .75em;
  }

  .social-text {
    font-size: .8em;
  }

  .privacy-terms {
    font-size: .75em;
  }
}